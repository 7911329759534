import React, { FC, ReactElement, useState, useEffect } from "react";
import Header from "./header";
import { Routes, Route } from "react-router-dom";
import { mergeStyles, Stack } from "@fluentui/react";
import {
  headerStackStyles,
  mainStackStyles,
  rootStackStyles,
  sidebarStackStyles, // Updated
} from "../ux/styles";
import { useAuth0 } from "@auth0/auth0-react";
import ChatPage from "../pages/chatPage";
import Menu from "./chatSidebar";
import LandingPage from "../pages/landingPage";
import DevChatPage from "../pages/devChatPage";
import logo from "../assets/logo.png";
import axios from "axios";
import config from "../config";

const Layout: FC = (): ReactElement => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();

  const [colorMode, setColorMode] = useState<"light" | "dark">("dark"); // Lifted colorMode state
  const [qqs, setQqs] = useState<string[]>([]);

  useEffect(() => {
    const postUsername = async () => {
      if (user && user.name) {
        try {
          const response = await axios.post(`${config.api.baseUrl}/chat/qqs`, {
            username: user.name,
          });
          setQqs(
            Object.keys(response.data.rows)
              .filter(
                (key) => key.startsWith("q") && response.data.rows[key] !== null
              )
              .map((key) => response.data.rows[key])
          );
        } catch (error) {
          console.error("Failed to post username:", error);
        }
      }
    };

    postUsername();
  }, [user]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const logoutButtonClass = mergeStyles({
    marginRight: "10px",
    borderRadius: "20px",
    padding: "5px 20px",
    cursor: "pointer",
    color: "white",
    border: `1px solid #3B9161`,
    backgroundColor: "#3B9161",
    ":hover": {
      backgroundColor: "#3B9161",
    },
  });

  const appParts = (
    <>
      <Stack.Item styles={headerStackStyles}>
        {/* <Header username={user?.name ?? ''}></Header> */}
      </Stack.Item>
      <Stack horizontal grow={1}>
        <Stack.Item styles={sidebarStackStyles(colorMode)}>
          {" "}
          <Menu
            username={user?.name ?? ""}
            colorMode={colorMode}
            setColorMode={setColorMode}
          />
        </Stack.Item>
        <Stack.Item grow={1} styles={mainStackStyles(colorMode)}>
          <Routes>
            <Route
              path="/chat/:chatId"
              element={
                <ChatPage
                  theme={colorMode} // Use colorMode for theme
                  convoStarters={qqs}
                  username={user?.name ?? ""}
                />
              }
            />
            <Route
              path="/chat"
              element={
                <ChatPage
                  theme={colorMode} // Use colorMode for theme
                  convoStarters={qqs}
                  username={user?.name ?? ""}
                />
              }
            />
            <Route
              path="/dev_chat"
              element={<DevChatPage username={user?.name ?? ""} />}
            />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Stack.Item>
      </Stack>
    </>
  );

  return (
    <Stack styles={rootStackStyles}>
      {isAuthenticated && appParts}
      {!isAuthenticated && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 20px)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50%",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: "40%", width: "auto", marginBottom: "100px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50%",
              alignItems: "flex-start",
            }}
          >
            <button
              onClick={() => loginWithRedirect()}
              className={logoutButtonClass}
            >
              Log In
            </button>
          </div>
        </div>
      )}
    </Stack>
  );
};

export default Layout;
