import React, { useEffect, useState, useCallback } from "react";
import SQLTableLoader from "./sqlTableLoader";
import { ChatInputData, commonIconImageStyle } from "../pages/chatPage";
import { mergeStyles } from "@fluentui/react";
import { TbSql } from "react-icons/tb";
import axios from "axios";
import config from "../config";
import HCChart from "./hcChart";
import { FiCopy, FiThumbsDown, FiThumbsUp, FiCheck } from "react-icons/fi";
import {
  canThisBeAChart,
  formatSQL,
  getSqlCmdFromChatItem,
  shemaToUse,
} from "./hc/chatItemLookups";

const logoutButtonClass = mergeStyles({
  marginRight: "10px",
  borderRadius: "20px",
  padding: "5px 20px",
  cursor: "pointer",
  color: "white",
  border: `1px solid #3B9161`,
  backgroundColor: "#3B9161",
  ":hover": {
    backgroundColor: "#3B9161",
  },
  ":disabled": {
    cursor: "not-allowed",
    opacity: 0.6,
    backgroundColor: "#3B9161",
  },
});

const inlineIcon = mergeStyles({
  display: "inline-block",
  verticalAlign: "middle",
  marginRight: "10px",
  cursor: "pointer",
});

const ChatItemDetails = ({ username, chatId, chatItem, theme }: { username: string, chatId: string | undefined, chatItem: ChatInputData, theme: 'light' | 'dark' }) => {
  const [activeTab, setActiveTab] = useState("Response");
  const [sqlFormatted, setSqlFormatted] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableData, setTableData] = useState<any[]>([]);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false);

  const parsedSql = getSqlCmdFromChatItem(chatItem);
  const hasSQL = parsedSql.length > 0;
  const hasChart = canThisBeAChart(chatItem);
  const schema = shemaToUse(chatItem);

  const toggleTab = useCallback((tabName: string) => {
    setActiveTab((prevTab) => (prevTab === tabName ? "" : tabName));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setFetchError(null);
      try {
        const response = await axios.post(
          `${config.api.baseUrl}/${
              chatItem.response.sql_id === 0
              ? "sql/read_table"
              : "sql/stored_table"
          }`,
          {
            sql: chatItem.response.sql_id === 0 ? parsedSql : undefined,
            sql_id:
              chatItem.response.sql_id !== 0
                ? chatItem.response.sql_id
                : undefined,
            schema,
          }
        );
        const data =
          chatItem.response.sql_id === 0
            ? response.data.rows
            : JSON.parse(response.data.stored_table).rows;
        setTableData(data);
      } catch (error) {
        setFetchError("Failed to load table data.");
        console.error("Error fetching table data:", error);
        setTableData([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (hasSQL) {
      fetchData();
    }
  }, [parsedSql, chatItem.response.sql_id, hasSQL, schema]);

  useEffect(() => {
    setThumbsUpClicked(false);
    setThumbsDownClicked(false);
    if (chatItem.feedback.thumbs === true) {
      setThumbsUpClicked(true);
    } else if (chatItem.feedback.thumbs === false) {
      setThumbsDownClicked(true);
    }
  }, [chatItem]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(chatItem.response.answer).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  }, [chatItem.response.answer]);

  const handleThumbsUpClick = useCallback(async () => {
    if (!thumbsUpClicked && !thumbsDownClicked) {
      try {
        if (!chatItem.question_id) {
          throw new Error("Chat ID is not defined.");
        }
        await axios.post(`${config.api.baseUrl}/chat/question_feedback`, {
          question_id: parseInt(chatItem.question_id, 10),
          username: username,
          thumbs: 'TRUE',
        });
  
        setThumbsUpClicked(true);
        setThumbsDownClicked(false);
      } catch (error) {
        console.error("Error submitting thumbs up feedback:", error);
      }
    }
  }, [thumbsUpClicked, thumbsDownClicked, chatItem.question_id, username]);

  const handleThumbsDownClick = useCallback(async () => {
    if (!thumbsDownClicked && !thumbsUpClicked) {
      try {
        if (!chatItem.question_id) {
          throw new Error("Chat ID is not defined.");
        }
        await axios.post(`${config.api.baseUrl}/chat/question_feedback`, {
          question_id: parseInt(chatItem.question_id, 10),
          username: username,
          thumbs: 'FALSE',
        });
  
        // Update the local state
        setThumbsDownClicked(true);
        setThumbsUpClicked(false);
      } catch (error) {
        console.error("Error submitting thumbs down feedback:", error);
      }
    }
  }, [thumbsDownClicked, thumbsUpClicked, chatItem.question_id, username]);

  return (
    <div style={{ paddingBottom: "25px" }}>
      <div style={{ paddingBottom: "30px" }}>
        {hasSQL && (
          <>
            <button
              className={logoutButtonClass}
              onClick={() => toggleTab("SQL")}
            >
              Code
            </button>
            <button
              className={logoutButtonClass}
              onClick={() => toggleTab("Table")}
              disabled={tableData.length === 0}
            >
              Table
            </button>
            <button
              className={logoutButtonClass}
              onClick={() => toggleTab("Chart")}
              disabled={tableData.length === 0}
            >
              Chart
            </button>
          </>
        )}
        <div
          className={inlineIcon}
          onClick={copyToClipboard}
          onMouseOver={(e) => (e.currentTarget.title = "Copy to clipboard")}
        >
          {copied ? <FiCheck size={16} /> : <FiCopy size={16} />}
        </div>
        <>
          <FiThumbsUp
            className={inlineIcon}
            size={16}
            onClick={!thumbsUpClicked && !thumbsDownClicked ? handleThumbsUpClick : undefined}
            style={{
              fill: thumbsUpClicked ? "white" : "none",
              cursor: thumbsUpClicked ? "default" : "pointer",
            }}
          />
          <FiThumbsDown
            className={inlineIcon}
            size={16}
            onClick={!thumbsDownClicked && !thumbsUpClicked ? handleThumbsDownClick : undefined}
            style={{
              fill: thumbsDownClicked ? "white" : "none",
              cursor: thumbsDownClicked ? "default" : "pointer",
            }}
          />
        </>
      </div>

      {activeTab === "SQL" && hasSQL && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            gap: "10px",
          }}
        >
          <div
            onClick={() => setSqlFormatted(!sqlFormatted)}
            className={commonIconImageStyle}
          >
            <TbSql size={24} />
          </div>{" "}
          {sqlFormatted ? (
            parsedSql
          ) : (
            <pre>
              <code>{formatSQL(parsedSql)}</code>
            </pre>
          )}
        </div>
      )}

      {activeTab === "Table" && hasSQL && (
        <SQLTableLoader
          theme={theme}
          isLoading={isLoading}
          error={fetchError}
          tableData={tableData}
        />
      )}

      {activeTab === "Chart" && hasSQL && (
        <HCChart
          isLoading={isLoading}
          error={fetchError}
          tableData={tableData}
          chartID={hasChart}
          theme={theme}
        />
      )}
    </div>
  );
};

export default ChatItemDetails;