import React, { FC, ReactElement, useEffect, useState, useRef } from "react";
import ChatInput from "../components/chatInput";
import config from "../config";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import ChatItemDetails from "../components/chatItemDetails";
import { mergeStyles, Spinner, SpinnerSize } from "@fluentui/react";
import Markdown from "markdown-to-jsx";
import logo_l from "../assets/image_light.png";
import logo_d from "../assets/image_dark.png";

export interface ChatInputData {
  message: string;
  question_id?: string;
  response: {
    answer: string;
    sql: {
      possible_to_answer: boolean;
      info: string;
      sql_cmd?: string;
    };
    history: string;
    sql_id: number;
    definitions: string[];
  };
  feedback: {
    display_name: string;
    notes: string;
    thumbs: boolean | null;
    feedback: string;
  };
}

export interface ErrorResponseData {
  error?: string;
  details?: string;
  original_q?: string | null;
}

export const commonIconImageStyle = mergeStyles({
  height: 40,
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: 10,
});

const colors = {
  dark: {
    background: "#000000",
    borderColor: "#343434",
    buttonHover: "#3B9161",
    text: "#ffffff",
    optionsBackground: "#2D2D2D",
    optionsHover: "#3B9161",
  },
  light: {
    background: "#ffffff",
    borderColor: "#e2e8f0",
    buttonHover: "#e2e8f0",
    text: "#000000",
    optionsBackground: "#f7fafc",
    optionsHover: "#e2e8f0",
  },
};

const ChatPage: FC<{
  username: string;
  convoStarters: string[];
  theme: "light" | "dark";
}> = (props): ReactElement => {
  const { username, convoStarters, theme } = props;
  const { chatId } = useParams();
  const location = useLocation();

  const [chatHistory, setChatHistory] = useState<ChatInputData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const extractHistory = (history: ChatInputData[]) => {
    const historyLength = history.length;
    if (historyLength === 0) {
      return "";
    }
    return history[historyLength - 1].response.history;
  };

  useEffect(() => {
    const getChatHistoryByID = async (id: string) => {
      setIsLoading(true);
      if (id === "0") {
        setIsLoading(false);
        setChatHistory([]);
        return;
      }

      try {
        const response = await axios.post(
          `${config.api.baseUrl}/chat/history`,
          { chat_id: id, username }
        );
        setChatHistory(response.data.chat);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching chat history:", error);
        setIsLoading(false);
      }
    };
    if (chatId) {
      getChatHistoryByID(chatId);
    }
  }, [chatId, location.key, username]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.post(`${config.api.baseUrl}/sql/colab`, {
          username,
        });
        if (response.data && response.data.rows.length > 0) {
          setIpAddress(response.data.rows[0].ml_aurora);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, [username]);

  const submittedFromChatInput = async (message: string) => {
    if (ipAddress === "") {
      console.error("No IP address found. Please try again later.");
      return;
    }

    try {
      setIsLoading(true);
      const axiosResponse = await axios.post(`${config.api.baseUrl}/chat`, {
        message,
        gradio_url: `${ipAddress}/user_question`,
        history: extractHistory(chatHistory),
        username,
      });

      const { answer, sql, history, definitions, question_id } = axiosResponse.data;
      const savedChat = { answer, sql, history, sql_id: 0, definitions };

      setChatHistory((prevHistory) => [
        ...prevHistory,
        { message, question_id, response: savedChat, feedback: { display_name: "", notes: "", thumbs: null, feedback: "" } },
      ]);
    } catch (error) {
      console.error("Error while calling the chat route:", error);

      // Extract error message from the response or use a default message
      let errorMessage = "An error occurred. Please try again.";
      if (axios.isAxiosError(error) && error.response) {
        const data = error.response.data as ErrorResponseData;
        if (data && data.error) {
          errorMessage = data.error;
          if (data.details) {
            errorMessage += ` -- ${data.details}`;
          }
        } else if (error.response.statusText) {
          errorMessage = error.response.statusText;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      // Add the user's message and the error message to chatHistory
      const errorResponse = {
        answer: errorMessage,
        sql: {
          possible_to_answer: false,
          info: "",
        },
        history: "",
        sql_id: 0,
        definitions: [],
      };

      setChatHistory((prevHistory) => [
        ...prevHistory,
        { message, response: errorResponse, feedback: { display_name: "", notes: "", thumbs: null, feedback: "" } },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleButtonClick = (message: string) => {
    submittedFromChatInput(message);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 25px)",
        padding: "5px",
        marginTop: "25px",
        boxSizing: "border-box",
        maxWidth: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: colors[theme].background,
        color: colors[theme].text,
      }}
    >
      {chatHistory.length === 0 && chatId === "0" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "70vh",
            paddingTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50%",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={theme !== "dark" ? logo_d : logo_l}
              alt="Logo"
              style={{ marginBottom: "20px" }}
            />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            {convoStarters.length > 0 &&
              convoStarters.map((starter, index) => (
                <button
                  key={index}
                  style={{
                    padding: "25px 20px",
                    cursor: isLoading ? "not-allowed" : "pointer",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    border: `1px solid ${colors[theme].borderColor}`,
                    borderRadius: "20px",
                    backgroundColor: isLoading
                      ? "#999"
                      : colors[theme].optionsBackground,
                    color: colors[theme].text,
                    lineHeight: "1.5",
                    textAlign: "center",
                    wordWrap: "break-word",
                    opacity: isLoading ? 0.6 : 1,
                  }}
                  disabled={isLoading}
                  onClick={() => handleButtonClick(starter)}
                >
                  {starter}
                </button>
              ))}
          </div>
        </div>
      )}
      <div
        style={{
          flexGrow: 1,
          overflowY: "auto",
          paddingBottom: "10px",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          scrollBehavior: "smooth",
        }}
        ref={messagesEndRef}
      >
        {chatHistory.map((chatItem, index) => (
          <div
            key={index}
            style={{ display: "flex", flexDirection: "column", gap: "5px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: `1px solid ${colors[theme].borderColor}`,
                gap: "10px",
                borderRadius: "20px",
                backgroundColor: colors[theme].optionsBackground,
                padding: "5px 15px",
                width: "fit-content",
                justifyContent: "flex-end",
                alignSelf: "flex-end",
              }}
            >
              {chatItem.message}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                gap: "10px",
              }}
            >
              <div className={commonIconImageStyle}>
                <img
                  src={theme !== "dark" ? logo_d : logo_l}
                  alt="Logo"
                  style={{ height: "100%", width: "auto" }}
                />
              </div>{" "}
              <div>
                <Markdown>{chatItem.response.answer}</Markdown>
              </div>
            </div>
            <ChatItemDetails username={username} chatId={chatId} theme={theme} chatItem={chatItem} />
          </div>
        ))}
      </div>
      <ChatInput
        theme={theme}
        onMessageSubmit={submittedFromChatInput}
        isDisabled={isLoading}
      />

      <div
        style={{
          textAlign: "center",
          marginTop: "10px",
          height: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Spinner
            size={SpinnerSize.small}
            label="Loading..."
            styles={{
              circle: { borderTopColor: colors[theme].text },
              label: { color: colors[theme].text },
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChatPage;
